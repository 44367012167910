/* Action types */
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_USER = "SET_USER"
export const SET_USER_ID = 'SET_USER_ID'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'

/* Action creators */

export const setAuthenticated = (authenticated) => {
  return {
    type: SET_AUTHENTICATED,
    authenticated,
  }
}

export const setUserId = (userId) => {
  return {
    type: SET_USER_ID,
    userId,
  }
}

export const toggleLoading = (loading) => {
  return {
    type: TOGGLE_LOADING,
    loading,
  }
}

export const setUser = (user) => {
  return {
    type: SET_USER_ID,
    user
  }
}
