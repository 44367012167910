module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"danmarksopsamling","defaultLang":"da-dk","langs":["da-dk"],"accessToken":"MC5YcUZlRmhBQUFDRUFSNm12.77-977-977-977-9di7vv71kSe-_vRvvv73vv73vv73vv70GQe-_vS5B77-977-9UO-_ve-_vQ4H77-9Pe-_ve-_vXY","path":"/preview","previews":true,"shortenUrlLangs":true,"omitPrismicScript":true,"pages":[{"type":"Article","match":"/blog/:uid","langs":["da-dk"],"component":"/vercel/workpath0/frontend/src/templates/article.jsx"},{"type":"User_page","match":"/app","langs":["da-dk"],"component":"/vercel/workpath0/frontend/src/templates/userpage.jsx"},{"type":"User_page","match":"/app/:uid?","langs":["da-dk"],"component":"/vercel/workpath0/frontend/src/templates/userpage.jsx"},{"type":"Page","match":"/:uid?","langs":["da-dk"],"component":"/vercel/workpath0/frontend/src/templates/page.jsx"},{"type":"Index","match":"/","langs":["da-dk"],"component":"/vercel/workpath0/frontend/src/templates/home.jsx"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Danmarks Opsamling","short_name":"dkopsamling","start_url":"/","background_color":"#F2F2F2","theme_color":"#BCBF5E","display":"standalone","icon":"src/images/logo-icon-coral.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
