import React, { useReducer, createContext } from 'react'
import { SET_USER } from 'app/actions/user'

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

const initialState = {
  user: null
}
const UserContext = createContext(initialState)
const { Provider } = UserContext


const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_USER:
        return {
          ...state,
          user: action.user,
        }
      default:
        return state
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { UserContext, UserProvider }
