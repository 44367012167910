/* Action types */
export const SET_USER = 'SET_USER'

/* Action creators */

export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  }
}
