/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Prismic Related
const { registerLinkResolver } = require('gatsby-source-prismic-graphql')
const { linkResolver } = require('./src/utils/linkResolver')

registerLinkResolver(linkResolver)
// \ Prismic Related

const React = require("react")
const { SiteProvider } = require("./src/context/SiteContext")
const { UserProvider } = require("./src/app/context/UserContext")

exports.wrapRootElement = ({ element }) => {
  return (
    <SiteProvider>
      <UserProvider>{element}</UserProvider>
    </SiteProvider>
  )
}
