import React, { useReducer, createContext } from "react"
import {
  SET_AUTHENTICATED,
  SET_USER_ID,
  SET_USER,
  TOGGLE_LOADING,
} from "actions/site"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

const initialState = {
  authenticated: null,
  userId: null,
  loading: false,
}
const SiteContext = createContext(initialState)
const { Provider } = SiteContext

const SiteProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_AUTHENTICATED:
        return {
          ...state,
          authenticated: action.authenticated,
        }
      case SET_USER_ID:
        return {
          ...state,
          userId: action.userId,
        }
      case TOGGLE_LOADING:
        return {
          ...state,
          loading: action.loading,
        }
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { SiteContext, SiteProvider }
